<template>
  <div>
    <PageLayout :headerWithBG="true">
      <PageLoader v-if="loading" class="p-4 mb-20" />
      <main v-else>
        <h1 class="fs-32 text__dark-grey mb-3">Update Profile</h1>

        <Tabs
          class="mb-3"
          @selected="activeSubPage = $event.component"
          :options="profilePages"
          type="rect"
          :defaultTab="defaultTab"
        />

        <template>
          <section>
            <transition name="fade-from-top" mode="out-in">
              <keep-alive>
                <component
                  :profileData="profileData"
                  @on-success="refreshData"
                  :is="activeSubPage"
                ></component>
              </keep-alive>
            </transition>
          </section>
        </template>
      </main>
    </PageLayout>
  </div>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import Tabs from "@/components/Tab";
import PageLoader from "@/components/PageLoader";
import { mapActions, mapGetters, mapMutations } from "vuex";
import MyProfile from "./SubPages/MyProfile.vue";
import Educations from "./SubPages/Educations.vue";
import Skills from "./SubPages/Skills.vue";
import SocialNetworks from "./SubPages/SocialNetworks.vue";
import Projects from "./SubPages/Projects";
import Resume from "./SubPages/Resume.vue";
import API from "@/const";
import seekerService from "../../../services/api/seekerService";

export default {
  name: "JobSeekerProfile",
  components: {
    PageLayout,
    Tabs,
    MyProfile,
    Educations,
    Skills,
    SocialNetworks,
    Projects,
    Resume,
    PageLoader
  },
  data() {
    return {
      profilePages: [
        {
          name: "My Profile",
          component: "MyProfile"
        },
        { name: "Educations", component: "Educations" },
        { name: "Experience and Skills", component: "Skills" },
        { name: "Social Networks", component: "SocialNetworks" },
        // { name: "Projects", component: "Projects" },
        { name: "Resume / CV", component: "Resume" }
      ],
      activeSubPage: "MyProfile",
      defaultTab: "",
      countries: [],
      loading: true
    };
  },
  created() {
    const tab = this.$route.query?.tab;
    if (tab) {
      this.getCurrentTab(tab);
    }
    this.getProfileData();
  },
  computed: {
    ...mapGetters({
      profileData: "authnew/getProfileData"
    })
  },
  methods: {
    ...mapMutations({
      setProfileData: "authnew/setProfileData"
    }),
    ...mapActions({}),
    getProfileData() {
      this.loading = true;
      seekerService
        .getSeekerProfile()
        .then((res) => {
          // console.log(res.data);
          // this.profileData = res.data;
          this.setProfileData(res.data);
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async refreshData({ tab = "" }) {
      await this.getProfileData();
      if (tab) this.getCurrentTab(tab);
    },
    getCurrentTab(tab) {
      const { name, component } = this.profilePages.find(
        (pg) => pg.component === tab
      );
      this.activeSubPage = component;
      this.defaultTab = name;
    }
  }
};
</script>

<style scoped lang="scss">
@use "../../../assets/scss/mixins/index" as *;

main {
  padding: 32px 80px 202px;

  @include query("mobile") {
    padding: 32px 16px 64px;
  }
}

section {
  padding: 0 24rem;

  @include query("mobile") {
    padding: 0;
  }
}
</style>
