<template>
  <div class="pt-4">
    <!-- <span class="fw-600 d-block fs-18 mb-2"> Social networks </span> -->
    <h1>hhhh</h1>
    <Accordion
      v-for="(sn, i) in profileData.socialNetworks"
      :key="i"
      type="secondary"
      :title="sn.site"
      class="mb-3"
    >
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="">
          <div class="grid grid__layout grid__mobile gap-3 mt-3" :key="sn.site">
            <div class="col-4" :style="{ zIndex: 100 - i }">
              <Dropdown
                :options="socialNetworks"
                @selected="setNetworkName($event, i)"
                :placeholder="sn.site || 'Network'"
                :border="true"
                :disabled="true"
              />
              -- mp--
            </div>
            <div class="col-8">
              <Input
                type="url"
                placeholder="Network link"
                v-model="sn.url"
                name="network link"
              />
            </div>
          </div>

          <div class="flex flex__spaced mt-3">
            <Button
              :disabled="deleting"
              class="w-20"
              :loading="deleting"
              theme="red"
              @click="confirmDelete(sn)"
              btnType="button"
            >
              Delete
            </Button>
            <Button
              :disabled="invalid || loading || !sn.site || !sn.url"
              class="w-20"
              :loading="loading"
              theme="blue"
              @click="updateNetwork(sn.id)"
            >
              Save Changes
            </Button>
          </div>
        </form>
      </ValidationObserver>
    </Accordion>

    <div class="mt-3 flex flex__center">
      <Button
        theme="dashed"
        @click="addMoreNetwork"
        class="w-23"
        btnType="button"
      >
        <div class="flex" style="margin-right: 10px">
          <img src="../../../../assets/icons/add-icon.svg" alt="add-icon" />
        </div>
        <span class="fs-14 fw-600" style="color: #8b90a0">
          Add more network
        </span>
      </Button>
    </div>

    <NewModal v-model="addModal" target="modal-portal">
      <form @submit.prevent="addNewNetwork">
        <h2 class="text-center">Add new social network</h2>

        <div class="flex ai-center mt-6">
          <div style="min-width: 122px">
            <Dropdown
              :options="socialNetworks"
              @selected="setNewNetworkName($event)"
              :placeholder="newNetwork.site || 'Network'"
              :border="true"
            />
          </div>

          <Input
            type="url"
            placeholder="Network link"
            v-model="newNetwork.url"
            name="network link"
            class="ml-2"
          />
        </div>
        <div class="flex flex-end mt-4 mb-6">
          <Button
            :disabled="!newNetwork.site || !newNetwork.url"
            class="w-20"
            :loading="addingNetwork"
            theme="blue"
          >
            Add
          </Button>
        </div>
      </form>
    </NewModal>

    <NewModal v-model="deleteModal" target="modal-portal">
      <h2 class="text-center mt-3" v-if="selectedNetwork.site">
        Are you sure? You are going to delete - <br />
        '{{ selectedNetwork.site }}'
      </h2>
      <div class="flex flex__spaced mt-5">
        <Button class="w-20" theme="red" @click="deleteNow"> Yes </Button>
        <Button class="w-20" theme="blue" @click="deleteModal = false">
          No
        </Button>
      </div>
    </NewModal>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion';

import { mapActions, mapMutations } from 'vuex';
import jobseekerService from '../../../../services/api/jobseekerservice';
import seekerService from '../../../../services/api/seekerService';
import publicService from '../../../../services/api/publicService';

export default {
  props: {
    profileData: {
      type: Object
    }
  },
  components: { Accordion },
  data() {
    return {
      formData: {},

      profile: {},
      socialNetworks: [
        // { name: "Facebook", value: "facebook" },
        // { name: "Twitter", value: "twitter" },
        // { name: "Linked In", value: "linkedin" },
        // { name: "Github", value: "github" }
      ],
      selectedNetwork: {},
      loading: false,
      deleting: false,
      deleteModal: false,
      addModal: false,
      addingNetwork: false,
      newNetwork: {
        site: '',
        url: ''
      }
    };
  },
  created() {
    const profile = this.profileData.profile;
    publicService
      .getNetworkList()
      .then((res) => {
        this.socialNetworks = res.data.networks.map((item) => ({
          name: item.title,
          value: item.title
        }));
      })
      .catch((e) => {})
      .finally(() => {});
  },
  computed: {
    nyscPlaceholder() {
      const profile = this.profileData.profile;
      return profile.nyscStatus || 'NYSC Status';
    }
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      updateLoggedUser: 'auth/updateLoggedUser'
    }),
    setNetworkName(event, i) {
      // this.formData.nyscStatus = status.value;
      const existingIndex = this.profileData.socialNetworks.findIndex(
        (network) => {
          return network.site === event.value;
        }
      );

      if (existingIndex > -1) {
        // this.addedNetworks[i].site = "";
        // this.addedNetworks.splice(i, 1);
        // this.addedNetworks.push({ site: "", url: "" });
        this.$handleError('This site already added!');
        return;
      }

      this.profileData.socialNetworks[i].site = event.value;
    },

    setNewNetworkName(event) {
      // this.formData.nyscStatus = status.value;
      const existingIndex = this.profileData.socialNetworks.findIndex(
        (network) => {
          return network.site === event.value;
        }
      );

      if (existingIndex > -1) {
        this.$handleError('This site already added!');
        return;
      }

      this.newNetwork.site = event.value;
    },

    addMoreNetwork(i) {
      if (this.profileData.socialNetworks.length < this.socialNetworks.length) {
        // this.addedNetworks.push({ site: "", url: "" });

        this.addModal = true;
      }
    },

    removeNework(i) {
      // this.addedNetworks.splice(i, 1);
    },
    confirmDelete(sn) {
      this.selectedNetwork = sn;
      this.deleteModal = true;
    },

    deleteNow() {
      this.deleteModal = false;
      this.deleting = true;
      seekerService
        .deleteSocialNetwork(this.selectedNetwork.id)
        .then((res) => {
          this.$handleSuccess(res);
          this.$emit('on-success', { tab: 'SocialNetworks' });
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.deleting = false;
        });
    },
    updateNetwork(id) {
      const sn = this.profileData.socialNetworks.find((data) => data.id === id);
      this.loading = true;

      seekerService
        .updateSocialNetwork(sn)
        .then((res) => {
          this.$handleSuccess(res);
          this.$emit('on-success', { tab: 'SocialNetworks' });
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });

      // const payload = {
      //   id,
      //   degreeId: education.degree.id,
      //   institute: education.institute,
      //   startDate: education.startDate,
      //   endDate: education.endDate,
      //   details: education.details,
      //   stillGoing: education.stillGoing
      // };
      // this.loading = true;

      // seekerService
      //   .updateEducation(payload)
      //   .then((res) => {
      //     this.$handleSuccess(res);
      //     this.$emit("on-success", { tab: "Education" });
      //   })
      //   .catch((e) => {
      //     this.$handleError(e);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    addNewNetwork() {
      // console.log(this.newNetwork);

      this.addingNetwork = true;
      seekerService
        .addNewSocialNetwork(this.newNetwork)
        .then((res) => {
          this.$handleSuccess(res);
          this.$emit('on-success', { tab: 'SocialNetworks' });
          this.addModal = false;
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.addingNetwork = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
