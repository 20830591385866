<template>
  <div>
    <div>
      <PageLoader v-if="loading" class="p-4 mb-20" />
      <div
        v-for="(cv, index) in documents"
        :key="index"
        class="cv__list flex flex__spaced flex__item-center"
        :class="[deleting ? 'disabled' : '', `z-${20 - index}`]"
      >
        <div class="flex flex__item-center flex__col-gap-1">
          <div class="flex">
            <img
              src="../../../../assets/icons/blue-file-icon.svg"
              alt="blue-file-icon"
            />
          </div>
          <div class="flex flex__column flex__col-gap-1">
            <p class="text__dark-grey fs-18 fw-600">
              {{ cv.name }}
            </p>
            <p class="text__dark-grey fs-12 fw-400">
              {{ $moment(cv.updatedAt).fromNow() }}
            </p>
          </div>
        </div>
        <div class="flex" style="min-width: 94px">
          <div class="cv__size fs-11">
            {{ Math.floor(Number(cv.size) / 1000) }} KB
          </div>
          <div class="u-relative flex flex__item-center">
            <div
              class="flex px-2 cursor-pointer"
              @click="activeDropdown = cv.id"
              tabindex="-1"
              @blur="closeDropdown"
            >
              <img
                src="../../../../assets/icons/more-icon.svg"
                alt="more-icon"
              />
            </div>
            <div
              class="cv__options"
              :class="{ active: activeDropdown === cv.id }"
            >
              <a
                :href="cv.url"
                target="_blank"
                class="d-block p-1 text__grey bb-1 cursor-pointer cv__options-list"
              >
                Download file
              </a>
              <p
                @click="deleteDocument(cv.id)"
                class="p-1 text-red cursor-pointer cv__options-list"
              >
                Delete
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2 class="fs-18 text__dark-grey py-3 fw-600">Upload CV</h2>
      <div class="flex flex__column flex__item-center">
        <FileGrabber
          v-model="document"
          :accept="['.pdf', '.doc', '.docx']"
          class="mb-3"
          insideText="Drop your CV here"
        />
        <Button
          @click="uploadDocument"
          :loading="uploading"
          :disabled="uploading || !document"
          theme="blue"
          class="w-21"
        >
          Upload CV
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import FileGrabber from '@/components/FileGrabber';
import API from '@/const';
import { mapActions } from 'vuex';
import seekerService from '../../../../services/api/seekerService';
import PageLoader from '@/components/PageLoader';

export default {
  name: 'Resume',
  components: {
    FileGrabber,
    PageLoader
  },
  props: {
    profileData: {
      type: Object
    }
  },
  data() {
    return {
      document: '',
      activeDropdown: null,
      loading: false,
      uploading: false,
      deleting: false,
      documents: []
    };
  },
  methods: {
    ...mapActions({
      $uploadDocument: 'apimodel/makeRequest',
      $deleteDocument: 'apimodel/makeRequest'
    }),
    closeDropdown() {
      setTimeout(() => (this.activeDropdown = null), 200);
    },
    uploadDocument() {
      const allowedFileTypes = ['pdf', 'PDF', 'doc', 'DOC', 'docx', 'DOCX'];

      const fileType = this.document.name.split('.').pop();
      if (allowedFileTypes.indexOf(fileType) < 0) {
        this.$handleError('Only DOC, DOCX and PDF file tyoes are allowed');
        return;
      }

      if (this.document.size / 1024 > 3 * 1024) {
        this.$handleError('Max file size allowed is 3MB');
        return;
      }

      this.uploading = true;
      const formData = new FormData();
      formData.append('cv', this.document);

      seekerService
        .uploadCv(formData, (percentage) => {
          console.log(percentage);
        })
        .then((res) => {
          this.$handleSuccess(res);
          // this.$emit("incrementStep");
          this.document = '';
          this.getAllDocuments();
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    deleteDocument(id) {
      this.deleting = true;
      seekerService
        .deleteCv(id)
        .then((res) => {
          // console.log(res.data);
          // this.documents = res.data.documents;
          this.$handleSuccess(res);
          this.getAllDocuments();
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.deleting = false;
        });

      // try {
      //   this.deleting = true;
      //   const { data } = await this.$deleteDocument({
      //     type: "delete",
      //     url: API.ADD_DOCUMENT,
      //     queries: { id }
      //   });
      //   if (!data.error) {
      //     this.$notify({
      //       type: "success",
      //       title: "Success",
      //       text: "Document deleted successfully"
      //     });
      //     this.$emit("on-success", { tab: "Resume" });
      //     return;
      //   }
      //   this.$notify({
      //     type: "error",
      //     title: "Error",
      //     text: data.message || "Something went wrong"
      //   });
      // } catch (error) {
      //   return error;
      // } finally {
      //   this.deleting = false;
      // }
    },
    getAllDocuments() {
      this.loading = true;
      seekerService
        .getCvs()
        .then((res) => {
          console.log(res.data);
          this.documents = res.data.documents;
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.getAllDocuments();
  }
};
</script>

<style scoped lang="scss">
.cv {
  &__list {
    padding: 40px 20px;
    padding-right: 0;
    border-bottom: 1px solid #ebeff2;
    position: relative;

    &.disabled:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
    }
  }

  &__size {
    padding: 2px 5px;
    color: #737373;
    border: 1px solid #cdd3d8;
    border-radius: 2px;
  }

  &__options {
    position: absolute;
    top: 34px;
    right: 0;
    padding: 16px 24px;
    background: red;
    min-width: 200px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 2px;
    transition: 0.3s all ease-out;
    opacity: 0;
    pointer-events: none;

    &-list {
      transition: 0.3s all ease-out;

      &:hover {
        background: darken(#fff, 2%);
      }
    }

    &.active {
      top: 30px;
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
