<template>
  <div>
    <Accordion type="secondary" title="Portal" class="mb-3" v-if="false">
      <div class="grid grid__layout grid__mobile gap-2 mb-2">
        <Input
          class="col-6 m-col-12 z-27"
          type="text"
          placeholder="Project Name"
          label="Project Name"
          v-model="formData.project_name"
          name="Project Name"
          rules="required"
        />
        <Input
          class="col-6 m-col-12 z-26"
          type="text"
          placeholder="Client Name"
          label="Client Name"
          v-model="formData.client_name"
          name="Client Name"
          rules="required"
        />
        <Input
          class="col-6 m-col-12 z-25"
          type="text"
          placeholder="Industry"
          label="Industry"
          v-model="formData.industry"
          name="Industry"
          rules="required"
        />
        <div class="col-6 m-col-12 z-24">
          <label class="fs-14 fw-600 text__dark-grey mb-1x"> Status </label>
          <Dropdown
            :options="status"
            @selected="updateSelected($event, 'degree')"
            placeholder="Status"
            :border="true"
          />
        </div>
        <div class="col-6 m-col-12 z-23">
          <label class="fs-14 fw-600 text__dark-grey mb-1x"> Start Date </label>
          <Datepicker
            v-model="formData.start_date"
            name="Start Date"
            placeholder="Start Date"
          />
        </div>
        <div class="col-6 m-col-12 z-22">
          <label class="fs-14 fw-600 text__dark-grey mb-1x"> End Date </label>
          <Datepicker
            v-model="formData.end_date"
            name="End Date"
            placeholder="End Date"
            :disabled="!formData.start_date"
            :disabled-dates="disabled_dates()"
          />
        </div>
        <div class="col-6 m-col-12 z-21 flex flex-end">
          <div class="flex flex__item-center">
            <span class="fs-14">Still going on</span>&nbsp;
            <toggle id="still-going-on" v-model="formData.still_going_on" />
          </div>
        </div>
        <div class="col-6" />
        <div class="col-12 m-col-12 z-20">
          <label class="fs-14 fw-600 text__dark-grey mb-1x">
            Description
          </label>
          <textarea
            placeholder="Add a description"
            class="h-12 p-2"
            v-model="formData.description"
          ></textarea>
        </div>
      </div>

      <div class="flex flex-end">
        <Button class="w-20" theme="blue">Save Changes</Button>
      </div>
    </Accordion>

    <Accordion type="secondary" title="IMS" class="mb-3" v-if="false">
      <div class="grid grid__layout grid__mobile gap-2 mb-2">
        <Input
          class="col-6 m-col-12 z-27"
          type="text"
          placeholder="Project Name"
          label="Project Name"
          v-model="formData2.project_name"
          name="Project Name"
          rules="required"
        />
        <Input
          class="col-6 m-col-12 z-26"
          type="text"
          placeholder="Client Name"
          label="Client Name"
          v-model="formData2.client_name"
          name="Client Name"
          rules="required"
        />
        <Input
          class="col-6 m-col-12 z-25"
          type="text"
          placeholder="Industry"
          label="Industry"
          v-model="formData2.industry"
          name="Industry"
          rules="required"
        />
        <div class="col-6 m-col-12 z-24">
          <label class="fs-14 fw-600 text__dark-grey mb-1x"> Status </label>
          <Dropdown
            :options="status"
            @selected="updateSelected($event, 'degree')"
            placeholder="Status"
            :border="true"
          />
        </div>
        <div class="col-6 m-col-12 z-23">
          <label class="fs-14 fw-600 text__dark-grey mb-1x"> Start Date </label>
          <Datepicker
            v-model="formData2.start_date"
            name="Start Date"
            placeholder="Start Date"
          />
        </div>
        <div class="col-6 m-col-12 z-22">
          <label class="fs-14 fw-600 text__dark-grey mb-1x"> End Date </label>
          <Datepicker
            v-model="formData2.end_date"
            name="End Date"
            placeholder="End Date"
            :disabled="!formData2.start_date"
            :disabled-dates="disabled_dates()"
          />
        </div>
        <div class="col-6 m-col-12 z-21 flex flex-end">
          <div class="flex flex__item-center">
            <span class="fs-14">Still going on</span>&nbsp;
            <toggle id="still-going-on-2" v-model="formData2.still_going_on" />
          </div>
        </div>
        <div class="col-6" />
        <div class="col-12 m-col-12 z-20">
          <label class="fs-14 fw-600 text__dark-grey mb-1x">
            Description
          </label>
          <textarea
            placeholder="Add a description"
            class="h-12 p-2"
            v-model="formData2.description"
          ></textarea>
        </div>
      </div>

      <div class="flex flex-end">
        <Button class="w-20" theme="blue">Save Changes</Button>
      </div>
    </Accordion>
  </div>
</template>

<script>
import Accordion from "@/components/Accordion";
import Toggle from "@/components/Toggle";
import Datepicker from "vuejs-datepicker";

export default {
  name: "Projects",
  components: {
    Accordion,
    Datepicker,
    Toggle
  },
  data() {
    return {
      formData: {},
      formData2: {},
      status: [
        { name: "Completed", value: "completed" },
        { name: "Pending", value: "pending" },
        { name: "Declined", value: "declined" }
      ]
    };
  },
  methods: {
    updateSelected({ value, id = "" }, key) {
      if (key === "country_id") {
        this.formData.country_id = id;
        const cities = countriesAndStates.find(
          (country) => country.code2 === value
        ).states;

        this.cities = cities.map((city, val) => ({
          name: city.name,
          value: city.name,
          id: val
        }));

        return;
      }

      this.formData[key] = value;
    },
    disabled_dates() {
      const date = this.formData.start_date
        ? [
            new Date(this.formData.start_date).getFullYear(),
            new Date(this.formData.start_date).getMonth(),
            new Date(this.formData.start_date).getDate() + 1
          ]
        : [new Date()];

      return {
        to: new Date(...date)
      };
    }
  }
};
</script>

<style scoped lang="scss">
.mb-1x {
  margin-bottom: 8px;
  display: inline-block;
}
</style>
